import { get, map } from 'lodash'
import { Link } from 'gatsby'
import React from 'react'
import { Fade, Flip } from 'react-reveal'

import {
  Button,
  Card,
  Columns,
  Content,
  Container,
  Heading,
  Section,
} from 'react-bulma-components'
import {
  Base,
  BasePreview,
  HtmlContent,
  Image as CommonImage,
} from 'components/common'
import Hero from 'components/hero'


export default function HomepageTemplate(props) {
  const {
    accreditations = [],
    heroImage = {},
    heroHeading,
    heroSubheading,
    introduction,
    previewMode,
    services,
    testimonial,
  } = props

  const BaseComponent = previewMode ? BasePreview : Base

  const heroBackground = get(heroImage, 'childImageSharp.fluid.src')
    || heroImage.value|| null

  return (
    <BaseComponent>

      <Hero backgroundImage={heroBackground} size="large">
        <Container className="has-text-centered padding-vertical-32">
          <Flip bottom>
            <Content size="large">
              <Heading className="has-text-white" size={1}>
                {heroHeading}
              </Heading>
              <Heading className="has-text-white" size={4}>
                {heroSubheading}
              </Heading>
            </Content>
          </Flip>
        </Container>
      </Hero>
      <Fade cascade>
        <Section>
          <Container className="has-text-centered padding-vertical-32">
            <Content size="medium">
              {introduction && <HtmlContent content={introduction} />}
              <Button
                color="info"
                outlined
                renderAs={Link}
                rounded
                size="large"
                style={{ marginTop: 32 }}
                to="/contact"
              >
                Get in touch
              </Button>
            </Content>
          </Container>
        </Section>
        <Section className="has-background-light has-moon-warning has-opacity-4">
          <Container className="has-text-centered padding-vertical-32">
            <Content size="medium">
              <Heading
                size={3}
                style={{ marginBottom: 32 }}
              >
                Services
              </Heading>
            </Content>
              <Columns>
                {map(services, (props, index) => (
                    <Columns.Column key={index} mobile={{ size: 12 }} size={4}>
                      <Card>
                        <div className="card-image">
                          <figure className="image is4by3">
                            <CommonImage imageInfo={{ image: props.image }} />
                          </figure>
                        </div>
                        <Card.Content>
                          <Content>
                            <Heading size={4}>{props.title}</Heading>
                            <p>{props.description}</p>
                          </Content>
                        </Card.Content>
                      </Card>
                    </Columns.Column>
                ))}
              </Columns>
          </Container>
        </Section>
          <Section>
            <Container className="has-text-centered padding-vertical-32">
              <Columns>
                {map(accreditations, (props, index) => (
                  <Columns.Column key={index} mobile={{ size: 12 }} size={4}>
                    <div style={{ margin: '0 auto', width: 240 }}>
                    <CommonImage imageInfo={{ image: props.image }} />
                    </div>
                  </Columns.Column>
                ))}
              </Columns>
            </Container>
          </Section>
      </Fade>
      <Hero className="has-zigs-footer-info has-opacity-4">
        <Container className="has-text-centered padding-vertical-32">
          <Content>
            <Heading className="no-text-shadow" size={4}>
              {testimonial[0].quote}
            </Heading>
            <p>- {testimonial[0].author}</p>
          </Content>
        </Container>
      </Hero>
    </BaseComponent>
  )
}
