import { get } from 'lodash'
import { graphql } from 'gatsby'
import React from 'react'

import { Base, Footer, Navigation } from 'components/common'
import HomepageTemplate from 'templates/home-page'

const getNode = obj => get(obj, 'edges.0.node', {})

export default function IndexPage({ data = {} }) {
  const { frontmatter: globals } = getNode(data.globals)
  const { html, frontmatter: homepage } = getNode(data.homepage)

  const {
    accreditations,
    footer,
    navigation,
  } = globals

  const {
    heroImage,
    heroHeading,
    heroSubheading,
    services,
    testimonial,
  } = homepage

  const { phoneNumber } = footer

  return (
    <Base>
      <Navigation {...navigation} phoneNumber={phoneNumber} />
      <HomepageTemplate
        accreditations={accreditations}
        heroImage={heroImage}
        heroHeading={heroHeading}
        heroSubheading={heroSubheading}
        introduction={html}
        services={services}
        testimonial={testimonial}
      />
      <Footer {...footer} />
    </Base>
  )
}

export const pageQuery = graphql`
query HomepageQuery {
  globals: allMarkdownRemark(
    filter: { frontmatter: { templateKey: { eq: "globals" } }}
  ) {
    edges {
      node {
        frontmatter {
          accreditations {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          footer {
            logo {
              childImageSharp {
                fluid(maxWidth: 400, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            emailAddress
            phoneNumber
          }
          navigation {
            logo {
              alternativeText
              image {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            pages {
              label
              path
            }
          }
        }
      }
    }
  }

  homepage: allMarkdownRemark(
    filter: { frontmatter: { templateKey: { eq: "home-page" } }}
  ) {
    edges {
      node {
        html
        frontmatter {
          heroHeading
          heroImage {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heroSubheading
          services {
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
          }
          testimonial {
            author
            quote
          }
        }
      }
    }
  }
}
`
